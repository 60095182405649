import { EventEmitter, Injectable } from '@angular/core';
import { ArmazenamentoService } from '../armazenamento/armazenamento.service';
import { UsuarioLogado } from '../../classes/usuario-logado';

@Injectable({
    providedIn: 'root'
})
export class UsuarioLogadoService {
    private usuario: UsuarioLogado
    usuarioEmitter = new EventEmitter()

    constructor(
        private armazenamentoS: ArmazenamentoService
    ) {
        this.carregarUsuarioLogado()
    }

    carregarUsuarioLogado = () => {
        this.usuario = JSON.parse(this.armazenamentoS.obterItem('LRLogU') || '{}')
        this.usuarioEmitter.emit()
    }

    obterUsuarioLogado = () => {
        return this.usuario
    }
}
