export class AppConfig {
    private static urlLRApi;

    public static obterUrlLRApi() {
        return this.urlLRApi;
    }

    public static definirUrlLRApi(url) {
        this.urlLRApi = url;
    }
}